<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BANK TRIAL & BALANCE REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              v-model="selection"
              class="mx-2"
              dense
              outlined
              label="Data"
              :items="['Current','Non Current']"
              :rules="rules.combobox_rule"
              @change="selected_selection"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-select
              v-model="type_of_bank"
              class="mx-2"
              dense
              outlined
              label="Purpose"
              :items="['ALL','TRUST FUND','EXPENSE','RESERVE FUND','INVESTMENT','REMITTANCE']"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-if="!branch_loading"
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="from_date"
              dense
              label="PDC Not Good From Date"
              outlined
              type="date"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              v-model="to_date"
              dense
              label="PDC Not Good To Date"
              outlined
              type="date"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
            <v-checkbox class="mx-2" v-model="view_for_deposit"
                        label="View for Deposit & for Transfer"
            ></v-checkbox>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"

            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="save_request"
              v-if="!saving_data"
            >
              Search
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>

          <!--          <v-flex xs12 md12 v-if="option_items.length>0">-->
          <!--            <v-select-->
          <!--              v-model="option"-->
          <!--              class="mx-2"-->
          <!--              dense-->
          <!--              outlined-->
          <!--              label="Option"-->
          <!--              :items="option_items"-->
          <!--              :rules="rules.combobox_rule"-->
          <!--              @change="selected_option"-->
          <!--            ></v-select>-->
          <!--          </v-flex>-->
        </v-layout>
      </v-form>
      <div class="mt-2" v-if="data_items.length>0">
        <h3 v-if="!saving_data" class="mx-2">PRINT
          <v-icon
            class="mr-2"
            color="success"
            @click="print_data"
          >
            {{icons.mdiPrinter}}
          </v-icon>
        </h3>

        <v-progress-circular
          :size=50
          :width="5"
          color="success"
          indeterminate
          v-else
        ></v-progress-circular>
        <h4 class="mx-2">
          Total Remaining: {{ total_remianing }}
        </h4>
        <!--        <h4 class="mx-2">-->
        <!--          Total For Deposit: {{ total_deposit }} | Total For Transfer: {{ total_transfer }}-->
        <!--        </h4>-->
        <h4 class="mx-2">
          Total For Return Fund: {{ total_return_fund }}
        </h4>
        <h4 class="mx-2">
          Total PDC: {{ total_pdc }} | Total EnCashment: {{ total_encash }}
        </h4>
        <h4 class="mx-2">
          Total Balance: {{ total_balance }}
        </h4>
      </div>
      <v-simple-table dense class="mt-3">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">
              Details
            </th>
            <th class="text-center">
              Credit
            </th>
            <th class="text-center">
              Debit
            </th>
            <th class="text-left">
            </th>
          </tr>
          </thead>
          <tbody>
          <td>
            <v-data-table dense
                          class="mt-3"
                          :headers="headers"
                          :items="data_items"
                          disable-pagination
                          hide-default-footer
            >

              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.purpose }}
                  </td>
                  <td>
                    {{ item.account_type }}
                  </td>
                  <td>
                    {{ item.bank_code }}
                  </td>
                  <td>
                    {{item.remaining_balance===0?'': formatPrice(item.remaining_balance) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </td>
          <td bgcolor="#add8e6">
            <v-data-table dense
                          class="mt-3"
                          :headers="headers2"
                          :items="data_items"
                          disable-pagination
                          hide-default-footer
            >

              <template v-slot:item="{ item }">
                <tr>
                  <td bgcolor="#add8e6">
                    {{ item.for_return_fund===0?'':formatPrice(item.for_return_fund) }}
                  </td>
                  <td bgcolor="#add8e6">
                    {{ item.for_deposit===0?'':formatPrice(item.for_deposit) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </td>
          <td bgcolor="#ffb6c1">
            <v-data-table dense
                          class="mt-3"
                          :headers="headers3"
                          :items="data_items"
                          disable-pagination
                          hide-default-footer
            >

              <template v-slot:item="{ item }">
                <tr>
                  <td bgcolor="#ffb6c1">
                    {{ item.pdc_not_good===0?'':formatPrice(item.pdc_not_good) }}
                  </td>
                  <td bgcolor="#ffb6c1">
                    {{ item.encashment===0?'':formatPrice(item.encashment) }}
                  </td>
                  <td bgcolor="#ffb6c1">
                    {{ item.for_transfer===0?'':formatPrice(item.for_transfer) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </td>
          <td>
            <v-data-table dense
                          class="mt-3"
                          :headers="headers4"
                          :items="data_items"
                          disable-pagination
                          hide-default-footer
            >

              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.total===0?'':formatPrice(item.total) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </td>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiPrinter} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      branch_loading: false,
      saving_data: false,
      is_search: false,
      alert: false,
      view_for_deposit: false,
      option_items: [],
      category_items: [],
      month_of_items: [],
      data_items: [],
      category_id: '',
      type_of_bank: '',
      option: '',
      from_date: '',
      to_date: '',
      alert_message: '',
      selection: '',
      month_of_id: '',
      total_remianing: '0.00',
      total_pdc: '0.00',
      total_balance: '0.00',
      total_return_fund: '0.00',
      total_encash: '0.00',
      total_deposit: '0.00',
      total_transfer: '0.00',
      headers: [
        {text: 'Purpose', value: 'date_of_deposit', sortable: false},
        {text: 'Account Type', value: 'date_of_deposit', sortable: false},
        {text: 'Bank Code', value: 'date_of_deposit', sortable: false},
        {text: 'Remaining Balance', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'Return Fund', value: 'last_name', sortable: false},
        {text: 'FTV(in)', value: 'last_name', sortable: false},
      ],
      headers3: [
        {text: 'CKV', value: 'last_name', sortable: false, color: "#ffb6c1"},
        {text: 'CV', value: 'last_name', sortable: false},
        {text: 'FTV(out)', value: 'last_name', sortable: false},
      ],
      headers4: [
        {text: 'Expected Remaining Balance', value: 'last_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPrinter,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('bank_transaction', ['trial_and_balance_data']),
      ...mapActions('category', ['list_of_categories_by_current']),
      ...mapActions('transaction_months', ['list_of_transaction_month', 'current_month']),
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async selected_option() {
        // await this.save_request()
      },
      async selected_selection() {
        this.branch_loading = true
        this.data_items = []
        this.option_items = []
        this.category_id = ''
        if (this.selection != '') {
          this.current_month()
            .then(response => {
              this.month_of_id = response.data.id
              this.list_of_categories_by_current({
                is_current: this.selection === 'Current' ? 1 : 0,
              })
                .then(response => {
                  this.category_items = response.data
                  this.category_items.splice(0, 0, {
                    id: -1,
                    category: 'All'
                  })
                  this.branch_loading = false
                })
                .catch(error => {
                  console.log(error)
                })

            })
          // await this.save_request()
        }
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      save_request() {
        this.data_items = []
        this.saving_data = true
        this.alert = true
        this.alert_message = 'PLEASE WAIT...'
        if (this.$refs.form.validate()) {
          this.trial_and_balance_data({
            month_of_id: this.month_of_id,
            type_of_bank: this.type_of_bank,
            is_cash_flow: 1,
            is_current: this.selection === 'Current' ? 1 : 0,
            category_id: this.category_id,
            option: this.option,
            from_date: this.from_date,
            to_date: this.to_date,
            view_for_deposit: this.view_for_deposit ? 1 : 0,
          })
            .then(response => {
              this.data_items = response.data
              var tto = 0
              var tto2 = 0
              var tto3 = 0
              var tto4 = 0
              var tto5 = 0
              var tto6 = 0
              var total_return_fundd = 0
              this.data_items.forEach(function (item) {
                tto += parseFloat(item.remaining_balance)
                tto2 += parseFloat(item.pdc_not_good)
                tto3 += parseFloat(item.total_new)
                tto4 += parseFloat(item.encashment)
                tto5 += parseFloat(item.for_deposit)
                tto6 += parseFloat(item.for_transfer)
                total_return_fundd += parseFloat(item.for_return_fund)
              })
              this.total_remianing = this.formatPrice(tto)
              this.total_pdc = this.formatPrice(tto2)
              this.total_encash = this.formatPrice(tto4)
              this.total_deposit = this.formatPrice(tto5)
              this.total_transfer = this.formatPrice(tto6)
              this.total_balance = this.formatPrice(tto3)
              this.total_return_fund = this.formatPrice(total_return_fundd)

              this.saving_data = false
              this.alert = false
            })
            .catch(error => {
              console.log(error)
            });
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      async selected_category(value) {
        this.data_items = []
        this.option_items = []
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.month_of_id = this.category_items[index].month_of_id
          switch (this.category_items[index].category) {
            case "Damayan":
              this.option_items = ['All', 'DAM']
              break
            case "Chapel":
              this.option_items = ['All', 'SERV']
              break
            case "HO":
              this.option_items = ['All', 'TRUST FUND', 'EXPENSE', 'RESERVE FUND', 'INVESTMENT']
              break
          }
          this.option = 'All'
        }
        // await this.save_request()
      },
      print_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var payments_array = []
          var widths = []
          var payment = 0
          var info_array = []

          var title_print = 'BANK TRIAL & BALANCE REPORT'

          widths = [130, 60, 60, 60, 60, 60, 70]
          payments_array.push([
            {text: 'Bank Code', alignment: 'left', style: 'main_info',},
            {text: 'Remaining Balance', alignment: 'left', style: 'main_info'},
            {text: 'CKV', alignment: 'left', style: 'main_info'},
            {text: 'CV', alignment: 'left', style: 'main_info'},
            {text: 'FTV(in)', alignment: 'left', style: 'main_info'},
            {text: 'FTV(out)', alignment: 'left', style: 'main_info'},
            {text: 'Expected Remaining Balance', alignment: 'left', style: 'main_info'},
          ])
          this.data_items.forEach(function (item) {
            payments_array.push([
              {
                text: item.bank_code,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.remaining_balance === 0 ? '' : (item.remaining_balance / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.pdc_not_good === 0 ? '' : (item.pdc_not_good / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.encashment === 0 ? '' : (item.encashment / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.for_deposit === 0 ? '' : (item.for_deposit / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.for_transfer === 0 ? '' : (item.for_transfer / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.total === 0 ? '' : (item.total / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
          info_array = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Category: ',
                    {
                      text: this.category_items[this.category_items.map(function (x) {
                        return x.id;
                      }).indexOf(this.category_id)].category + ' ( ' + this.selection + ' )',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Total Remaining: ',
                    {
                      text: this.total_remianing,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Total For Deposit: ',
                    {
                      text: this.total_deposit,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Total For Transfer: ',
                    {
                      text: this.total_transfer,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Total PDC: ',
                    {
                      text: this.total_pdc,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Total EnCashment: ',
                    {
                      text: this.total_encash,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Total Balance: ',
                    {
                      text: this.total_balance,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'PDC Not Good From : ',
                    {
                      text: this.from_date,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'PDC Not Good To : ',
                    {
                      text: this.to_date,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
          ]

          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'portrait',
            content: [
              {
                columns: [
                  {image: imgData, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'GOODLIFE GROUP OF COMPANIES',
                      {text: this.address, style: 'subheader'},
                      {
                        text: this.contact,
                        style: 'subheader',
                      },
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header',
                  },
                ],
              },
              '_______________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: title_print,
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: info_array,
              },
              {
                columns: [{text: ' '}],
              },
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.position,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {image: this.damayan, width: 54, height: 40, style: 'logo'},
                  {image: this.chapel, width: 54, height: 40, style: 'logo'},
                  {image: this.factory, width: 54, height: 40, style: 'logo'},
                  {image: this.coop, width: 54, height: 40, style: 'logo'},
                  {image: this.printing, width: 54, height: 40, style: 'logo'},
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      }
    },
  }
</script>
